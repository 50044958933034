<template>
    <div ref="observer" />
</template>

<script>
export default {
    // Componente criado para implementar funcionalidade de scroll infinito
    props: ["options"],
    data: () => ({
        observer: null,
    }),
    mounted() {
        const options = this.options || {};
        this.observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting) {
                this.$emit("intersect");
            }
        }, options);

        this.observer.observe(this.$el);
    },
    destroyed() {
        this.observer.disconnect();
    },
};
</script>
