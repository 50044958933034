<template>
  <div>
    <Loading v-show="orders.length === 0 && isFirstLoading" />

    <NotFound v-if="orders.length === 0 && !isFirstLoading" />

    <div v-else :class="['order-list']">
      <div
        v-for="(order, index) in orders"
        :key="`order-card-${index}`"
        @click="pressCard(order)"
      >
        <OrderCard :order="order" :selectable="selectMode" />
      </div>

      <Observer @intersect="$emit('loadMoreOrders')" />

      <div v-if="selectMode" class="float-bar">
        <span v-if="selectedOrders.length === 0" class="filtered-orders">
          {{ $t("Operator.OrderList.NoOrderSelected") }}
        </span>
        <span v-else-if="selectedOrders.length === 1" class="filtered-orders">
          {{ selectedOrders.length }}
          {{ $t("Operator.OrderList.SelectedOrder") }}
        </span>
        <span v-else-if="selectedOrders.length > 1" class="filtered-orders">
          {{ selectedOrders.length }}
          {{ $t("Operator.OrderList.SelectedOrders") }}
        </span>

        <div class="box-btn">
          <button class="btn-cancel" @click="cancelSelection">
            {{ $t("ResourceStatus.Cancel") }}
          </button>

          <button class="btn-clean" @click="cleanSelectedOrder">
            {{ $t("Operator.Clean") }}
          </button>

          <button
            :disabled="selectedOrders.length === 0"
            class="btn-point"
            @click="() => $refs.appointmentTypeModal.open()"
            v-if="checkSelectedOrders()"
          >
            {{ $t("Operator.Point") }}
            <ArrowDown class="arrow-down-icon" />
          </button>

          <button
            :disabled="selectedOrders.length === 0"
            class="btn-point"
            @click="() => redirectOrderByFloatBar(1)"
            v-else
          >
            {{ $t("Operator.FinishedOrdersBar.Rework") }}
          </button>
        </div>
      </div>
    </div>
    <SelectAppointmentTypeModal
      ref="appointmentTypeModal"
      @select="(selectedId) => redirectOrderByFloatBar(selectedId)"
    />
  </div>
</template>

<script>
import Loading from "@/@core/components/loading";
import ArrowDown from "@core/assets/icons/arrow-down.svg";
import Observer from "../events/Observer.vue";
import OrderCard from "./OrderCard.vue";
import NotFound from "./NotFound.vue";
import SelectAppointmentTypeModal from "./SelectAppointmentTypeModal.vue";

export default {
  components: {
    OrderCard,
    Observer,
    NotFound,
    Loading,
    ArrowDown,
    SelectAppointmentTypeModal,
  },
  props: {
    selectMode: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Array,
    },
    selectedOrders: {
      type: Array,
    },
    isFirstLoading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      prefix:
        localStorage.getItem("prefix") ||
        this.$router.currentRoute.params.prefix,
    };
  },
  methods: {
    redirectOrderByFloatBar(appointment_type) {
      const isMultipleOrders = this.selectedOrders.length > 1;

      const routerParams = {
        name: isMultipleOrders
          ? "operator-production-progress-multiple"
          : "operator-production-progress",
        params: {
          prefix: this.prefix,
          resource_id: this.selectedOrders[0].resource_id,
          multiple: isMultipleOrders.toString(),
          orders: isMultipleOrders ? this.selectedOrders : [],
          appointment_type,
        },
      };

      if (isMultipleOrders) {
        routerParams.params.preactor_orders = this.selectedOrders
          .map((item) => item.preactor_order_id)
          .join(",");
      }

      if (!isMultipleOrders) {
        routerParams.params.return_orders = "true";
        routerParams.params.order_id = this.selectedOrders[0].preactor_order_id;
      }
      this.$router.push(routerParams);
    },
    selectOrder(id) {
      this.$emit("selectOrder", id);
    },
    cleanSelectedOrder() {
      this.$emit("cleanSelectedOrder");
    },
    cancelSelection() {
      this.$emit("update:selectMode", false);
      this.cleanSelectedOrder();
    },
    redirectOrder(resource_id, preactor_order_id, is_rework) {
      const routerParams = {
        name: "operator-production-progress",
        params: {
          prefix: this.prefix,
          resource_id,
          order_id: preactor_order_id,
          multiple: "false",
          return_orders: "true",
          orders: [],
          appointment_type: is_rework
        },
      };

      this.$router.push(routerParams);
    },
    pressCard({ id, resource_id, preactor_order_id, is_rework }) {
      if (this.selectMode) {
        this.selectOrder(preactor_order_id);

        return;
      }

      this.redirectOrder(resource_id, preactor_order_id, is_rework);
    },
    checkSelectedOrders() {
      if(this.selectedOrders.length === 0)
        return true;

      const order = this.selectedOrders[0];

      return order.is_rework == '0';
    }
  },
};
</script>

<style lang="scss" scoped>
.arrow-down-icon {
  width: 16px;
  height: 16px;
}
.order-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
}

.float-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px;

  background: #ffffff;
  border: 1px solid #cfc4be;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
    0px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;

  position: fixed;
  bottom: 71.5px;
  z-index: 10;
  font-size: 12px;
  outline: none;
  right: 28px;
  width: 100%;
  max-width: 662px;

  .filtered-orders {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #4c4541;
    margin-right: auto;
  }
}

.box-btn {
  display: flex;
  gap: 15px;
  justify-content: end;

  button {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 5px;
    padding: 8px 20.25px;
    border: 1px solid transparent;
    letter-spacing: 0.0125em;
  }

  .btn-cancel {
    background: #fff;
    color: #974900;

    &:hover {
      background-color: #ffede2 !important;
    }

    &:active {
      background-color: #ffdbc4 !important;
    }
  }

  .btn-clean {
    color: #974900;
    background-color: #ffffff;
    border-color: #974900;

    &:hover {
      background-color: #ffede2 !important;
    }

    &:active {
      background-color: #ffdbc4 !important;
    }
  }

  .btn-point {
    color: #ffffff !important;
    border-color: #974900 !important;
    background-color: #974900 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 119px !important;

    &:disabled {
      background: #ece0db !important;
      color: #7e7570 !important;
      border: transparent !important;

      svg,
      path {
        fill: #7e7570 !important;
      }

      &:hover,
      &:active {
        background: #ece0db !important;
        color: #7e7570 !important;
      }
    }

    &:hover {
      background-color: #9f5714 !important;
    }

    &:active {
      background-color: #a45f1f !important;
    }
  }
}

@media (max-width: 768px) and (min-width: 593px) {

  .float-bar {
    right: 0;
    left: 0;
    width: calc(100% - 58px);
    margin: 0 auto;
    gap: 16px !important;
    max-width: unset !important;

    .filtered-orders {
      font-size: 18px;
    }
  }

  .btn-clean {
    height: 46px !important;
  }

  .btn-point {
    height: 46px !important;
    line-height: 33px !important;
    width: 146px !important;
  }
}

@media (max-width: 593px) {
  .float-bar {
    right: 0;
    left: 0;
    margin: 0 auto;
    flex-direction: column;
    width: calc(100vw - 56px);
    height: auto;
    gap: 0;

    .filtered-orders {
      margin: 0;
    }

    .box-btn {
      padding: 16px 0 0 0;

      button {
        padding: 3px 13px !important;
        font-size: 12px !important;
        line-height: 20px !important;
      }
    }
  }
}

@media (max-width: 575px) {
  .float-bar {
    right: 0;
    left: 0;
    width: calc(100vw - 32px);
  }
}
</style>
