<template>
    <div id="resources-tab">
        <div class="resources-cards-container" v-if="resources.length > 0">
            <Card
                v-for="resource in resources"
                :key="resource.id"
                :resource="resource"
                @selectResource="$emit('onClickResource', resource)"
            />
            <Observer @intersect="$emit('loadMoreResources')" />
        </div>

        <NotFound v-else/>
    </div>
</template>

<script>
import Observer from "../../events/Observer.vue";
import Card from "./Card.vue";

export default {
    components: {
        Observer,
        Card,
        NotFound: () => import('../NotFound.vue'),
    },
    props: ["resources"],
};
</script>

<style lang="scss">
#resources-tab {
    .resources-cards-container {
        padding-left: 0px !important;
        & > div {
            margin-bottom: 16px;
        }
    }
}
</style>
