<template>
  <transition name="selectAppointmentTypeModal">
    <SelectModal
      v-if="isAppointmentTypeModalOpen"
      :title="$t(`Operator.SelectAppointmentType.Title`)"
      :items="items"
      @close="close"
      @select="select"
    />
  </transition>
</template>

<script>
import SelectModal from "@/@core/components/select-modal/index.vue";

export default {
  components: { SelectModal },
  data: () => ({
    isAppointmentTypeModalOpen: false,
  }),
  computed: {
    items() {
      return [
        {
          id: 0,
          name: this.$t("Operator.SelectAppointmentType.Items.Productive"),
        },
        {
          id: 1,
          name: this.$t("Operator.SelectAppointmentType.Items.Rework"),
        },
      ];
    },
  },
  methods: {
    open() {
      this.isAppointmentTypeModalOpen = true;
    },
    close() {
      this.isAppointmentTypeModalOpen = false;
    },
    select(id) {
      this.$emit("select", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.selectAppointmentTypeModal-enter-active,
.selectAppointmentTypeModal-leave-active {
  transition: all 0.5s;
}
.selectAppointmentTypeModal-enter,
.selectAppointmentTypeModal-leave-to {
  margin-top: 100dvh;
}
</style>
