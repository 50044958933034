<template>
    <div
        class="resource-card"
        :style="{ borderLeft: `4px solid ${resource.color}` }"
        @click="$emit('selectResource', resource)"
    >
        <h1>{{ resource.alias ? resource.alias : resource.name }}</h1>
        <span
            :style="{
                color: String(resource.color),
                backgroundColor: `${resource.color}20`,
                minWidth:
                    this.resource.status.length < 20 &&
                    'fit-content !important',
            }"
            >{{ resource.status }}</span
        >
    </div>
</template>

<script>
export default {
    props: ["resource"],
};
</script>

<style lang="scss">
.resource-card {
    width: 100%; //calc(99.48231%);
    height: 74px;
    padding: 24px 16px;

    background: #fff;
    cursor: pointer;
    filter: drop-shadow(0px 4px 24px rgba(58, 46, 38, 0.1));
    border-radius: 6px;

    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    &:hover {
        background-color: #ffede2;
    }
    &:active {
        background-color: #ffdbc4;
    }

    h1,
    span {
        font-family: "Raleway", sans-serif;
        font-style: normal;
        font-weight: 600;

        word-break: break-all;
        white-space: pre-wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    h1 {
        font-size: 16px;
        line-height: 26px;

        max-width: 77.69%;
        margin-bottom: 0;
    }
    span {
        max-width: 49.44%;

        padding: 1px 9px;
        border-radius: 4px;

        font-size: 12px;
        line-height: 16px;

        user-select: none;
    }
}

@media (max-width: 480px) {
    .resource-card {
        min-height: 134px !important;
        height: max-content;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px;
        h1 {
            font-size: 18px;
            line-height: 26px;
            max-width: 100%;
            order: 2;
        }
        span {
            font-size: 12px !important;
            line-height: 16px !important;
            max-width: 100%;
        }
    }
}
@media (min-width: 480px) and (max-width: 768px) {
    .resource-card {
        width: calc(98.8769%) !important;
        height: 120px;
        h1 {
            font-size: 26px !important;
            line-height: 36px !important;
        }
        span {
            font-size: 16px !important;
            line-height: 26px !important;
        }
    }
}
</style>
