<template>
    <div class="loading-component">
        <LoadSpinner class="load-spinner-global"/>
    </div>
</template>

<script>
    export default {
        name: 'LoadingComponent',
        components: {
            LoadSpinner: () => import('@/assets/images/pages/load-icon.svg'),
        }
    }
</script>

<style lang="scss" scoped>
    .loading-component {
        background: red;
        height: 100dvh;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        align-content: center;
        justify-content: center;
        background: rgba(235, 235, 235, 0.8) !important;
        backdrop-filter: blur(20px) !important;

        .load-spinner-global {
            height: 50px !important;
            width: 50px !important;

            circle {
                fill: #974900 !important;
            }
        }
    }
</style>
