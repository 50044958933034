<template>
    <div id="select-appointment-type-modal">
        <header>
            <h1>{{ title }}</h1>
            <CloseIcon class="x-icon-select-modal" @click="$emit('close')" />
        </header>
        <div class="select-modal__content">
            <ul class="select-modal__list">
                <li v-for="item of items" :key="`select-option-${item.id}`" @click="$emit('select', item.id)">
                    {{ item.name }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import CloseIcon from '@/assets/images/icons/x-icon-1.svg';

export default {
    name: 'SelectListModal',
    components: { CloseIcon },
    props: {
        title: {
            type: String,
            default: 'Título',
        },
        items: {
            type: Array,
            default: () => [
                {
                    id: 1,
                    name: 'Item 1',
                },
            ],
        },
    },
}
</script>

<style lang="scss">
.x-icon-select-modal {
    fill: #4c4541;
    cursor: pointer;
    align-self: center;
    padding: 0;
    width: 16px;
    height: 16px;
}
</style>

<style lang="scss" scoped>
#select-appointment-type-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
    background: #fcfcfc;

    header {
        width: 100%;
        padding: 16px 29px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: 1px solid #cfc4be;
        box-shadow: 0px 1px 8px 0px #00000033;
        box-shadow: 0px 3px 3px 0px #0000001f;
        box-shadow: 0px 3px 4px 0px #00000024;

        h1 {
            all: unset;
            font-size: 22px;
            line-height: 30px;
            font-weight: 600;
            color: #4c4541;
        }
    }

    .select-modal__list {
        padding: 0;

        &>li {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            padding: 0 29px;
            border-bottom: 1px solid #cfc4be;
            height: 84px;

            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            list-style-type: none;
            cursor: pointer;

            &:hover {
                color: #974900;
                background-color: #ffede2;
                border-bottom: 1px solid #974900;
            }

            &:active {
                color: #974900;
                background-color: #ffdbc4;
                border-bottom: 1px solid #974900;
            }
        }
    }
}

@media (max-width: 768px) {
    #select-appointment-type-modal {
        header {
            h1 {
                font-size: 36px;
                line-height: 50px;
            }
        }

        .select-modal__list>li {
            height: 94px;
            font-size: 22px;
            line-height: 30px;
        }
    }
}

@media (max-width: 480px) {
    #select-appointment-type-modal {
        header {
            height: 58px;
            padding: 16px;

            h1 {
                font-size: 18px;
                line-height: 26px;
            }
        }

        .select-modal__list>li {
            height: 80px;
            font-size: 12px;
            line-height: 16px;
        }
    }
}
</style>
